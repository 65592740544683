<template>
  <div class="w-full flex flex-col justify-center items-center gap-10">
    <Wrapper class="max-w-2xl">
      <h2 class="text-2xl text-left text-blue-dark mb-2"><b>Detalle de la solicitud</b></h2>       
      <ComplaintDetailHeaderCard :radicado="solicitude.code" detail="true"
        :status="solicitude.solip? (solicitude.solip.status? solicitude.solip.status : 'Abierto'): 'Abierto'" />
      <form ref="formSolicitudeCF" @submit.prevent="sendComplaint" class="w-full px-0 py-0">
        <!-- Persona natural -->
        <div class="w-full" v-if="solicitude.user_cf && solicitude.user_cf.tipo_persona == 1">
          <h2 class="my-5 text-lg text-left text-blue"><b>Datos de contacto</b></h2>
          <BaseInput type="text" label="Nombres" v-model="solicitude.user_cf.names_cf" placeholder="Julio Roman"
            :disabled="true" />
          <BaseInput type="text" label="Apellidos" v-model="solicitude.user_cf.surnames_cf" placeholder="Henriquez"
            :disabled="true" />
          <BaseInput type="text" label="Tipo de identificación" v-model="solicitude.user_cf.identification_type_cf"
            placeholder="C.C" :disabled="true" />
          <BaseInput type="text" label="Número de identificación" placeholder="Ej:123456789"
            v-model="solicitude.user_cf.numero_id_cf" :disabled="true" />
          <BaseInput type="text" label="Fecha de nacimiento" v-model="solicitude.user_cf.birth_date_user"
            placeholder="11/11/2011" :disabled="true" />
          <BaseInput type="text" label="Número de celular" placeholder="123412341234"
            v-model="solicitude.user_cf.phone_user" :disabled="true" />
          <BaseInput ref="email" type="text" label="Correo electrónico" v-model="solicitude.user_cf.email_user"
            placeholder="ejemplo@ejemplo.com" required="true" autocomplete="username" :disabled="true" />

          <BaseSelect ref="department" id="department" label="Seleccione un departamento"
            :selectOptions="formOptions.optionsDepartments" v-model="solicitude.user_cf.departamento_nombre_user"
            :disabled="true" v-if="user.departamento_cod" />
          <BaseSelect ref="city" id="city" label="Seleccione un municipio" :selectOptions="optionsMunicipalities2"
            v-model="solicitude.user_cf.municipio_nombre_user" :disabled="true"
            v-if="solicitude.user_cf.municipio_nombre_user" />
          <BaseInput ref="address" id="address" label="Dirección" v-model="solicitude.user_cf.direccion_user"
            :maxlength="50" placeholder="Cra. 1 ## 1A-11" type="text" v-if="solicitude.user_cf.direccion_user"
            :disabled="true" />
        </div>
        <!-- Persona júridica -->
        <div class="w-full" v-if="solicitude.user_cf && solicitude.user_cf.tipo_persona == 2">
          <h2 class="my-5 text-lg text-left text-blue"><b>Datos de contacto</b></h2>
          <BaseInput type="text" label="Nombres del representante legal" v-model="solicitude.user_cf.names_cf" placeholder="Julio Roman"
            :disabled="true" />
          <BaseInput type="text" label="Apellidos del representante legal" v-model="solicitude.user_cf.surnames_cf" placeholder="Henriquez"
            :disabled="true" />
          <BaseInput ref="nit" type="text" id="nit" label="NIT" v-model="solicitude.user_cf.numero_id_cf" :disabled="true"
            v-if="solicitude.user_cf.numero_id_cf" />
          <BaseInput ref="company_name" type="text" id="razonSocial" label="Razón social"
            v-model="solicitude.user_cf.business_name" :disabled="true" v-if="solicitude.user_cf.business_name" />
          <BaseInput type="text" label="Número de celular" placeholder="123412341234"
            v-model="solicitude.user_cf.phone_user" :disabled="true" />
          <BaseInput ref="email" type="text" label="Correo electrónico" v-model="solicitude.user_cf.email_user"
            placeholder="ejemplo@ejemplo.com" required="true" autocomplete="username" :disabled="true" />

          <BaseSelect ref="department" id="department" label="Seleccione un departamento"
            :selectOptions="formOptions.optionsDepartments" v-model="solicitude.user_cf.departamento_nombre_user"
            :disabled="true" v-if="solicitude.user_cf.departamento_nombre_user" />
          <BaseSelect ref="city" id="city" label="Seleccione un municipio" :selectOptions="optionsMunicipalities2"
            v-model="solicitude.user_cf.municipio_nombre_user" :disabled="true"
            v-if="solicitude.user_cf.municipio_nombre_user" />
          <BaseInput ref="address" id="address" label="Dirección" v-model="solicitude.user_cf.direccion_user"
            :maxlength="50" placeholder="Cra. 1 ## 1A-11" type="text" v-if="solicitude.user_cf.direccion_user"
            :disabled="true" />
        </div>
        <div v-if="customer && customer.person_type == 1">
          <BaseSelect ref="sexo" id="sexo" label="Sexo" :disabled="true" :selectOptions="formOptions.optionsGender"
            v-model="solicitude.user_cf.sexo" />
          <BaseRadio ref="lgtbiq" name="lgtbiq" label="LGTBIQ+" :disabled="true" :radioOptions="optionsYN"
            v-model="solicitude.user_cf.lgbtiq" />
          <BaseRadio ref="especialCondicion" name="especialCondicion" label="¿Tiene alguna condición especial?"
            :disabled="true" :radioOptions="optionsYN" :value="specialCondition" />
          <BaseSelect ref="typeSpecialCondition" v-if="specialCondition == 1" id="typeSpecialCondition" label="¿Cuál?"
            :disabled="true" :selectOptions="formOptions.optionsConditions" v-model="solicitude.user_cf.condicion_especial"
            required="true" />
        </div>
        <h2 class="my-5 text-xl text-left text-blue">
          <b>Detalles de la inconformidad</b>
        </h2>
        <BaseRadio label="¿La inconformidad que motiva su queja ocurrió el exterior?" :radioOptions="optionsYN"
          :value="solicitude.places.codigo_pais == '170' ? 2 : 1" required="true" :disabled="true" />
        <div v-if="solicitude.places.codigo_pais == '170'">
          <BaseSelect ref="department" id="department" label="Seleccione un departamento" :disabled="true"
            :selectOptions="formOptions.optionsDepartments" v-model="solicitude.places.departamento_nombre"
            required="true" />
          <BaseSelect ref="city" id="city" label="Seleccione un municipio" :selectOptions="optionsMunicipalities"
            v-model="solicitude.places.municipio_nombre" required="true" :disabled="true" />
        </div>
        <div v-if="solicitude.places.codigo_pais != '170'">
          <BaseSelect ref="country" id="country" label="Seleccione el país donde ocurrio la incidencia" :disabled="true"
            :selectOptions="formOptions.optionsCountries" v-model="solicitude.places.pais_nombre" required="true" />
        </div>
        <BaseSelect ref="entity" id="entity" label="¿Con qué entidad tuvo su inconformidad?" sublabel="(Si su queja es contra Liberty Seguros, por favor seleccione HDI Seguros Colombia)" :disabled="true"
          :selectOptions="formOptions.optionsCompanies" v-model="solicitude.entidad.company_name" required="true" />
        <BaseSelect ref="reason" id="reason" label="¿Cuál es el motivo de su inconformidad?"
          :selectOptions="optionsReasons" v-model="solicitude.detalle_solicitud.motivo_nombre" required="true"
          :disabled="true" />
        <BaseCheckbox
          v-if="solicitude.detalle_solicitud.check_habeas_data"
          id="habeas_data"
          :name="'habeas_data'"
          :showAll="false"
          :disabled="true"
          class="my-8"
          label="Si buscas que la SFC revise tu caso para la corrección, actualización, eliminación o retiro de tus datos financieros en los operadores o centrales de información, marca la casilla."
          :options="[{id: 1, name: 'Acepto', checked:true}]"
        />
        <BaseSelect ref="product" id="product"
          label="¿Su inconformidad está relacionada con alguno de los siguientes productos?"
          :selectOptions="optionsProducts" v-model="solicitude.detalle_solicitud.producto_nombre" required="true"
          :disabled="true" />
        <BaseTextarea ref="productDescription" id="productdescription"
          label="Si lo desea amplíe el detalle de este producto" placeholder="Descripción"
          v-model="solicitude.detalle_solicitud.detalle_producto" required="true" maxlength="100" rows="2"
          :disabled="true" />
        <BaseSelect ref="channel" id="channel" label="¿A través de que canal se originó su inconformidad?"
          :disabled="true" :selectOptions="formOptions.optionsChannel"
          v-model="solicitude.detalle_solicitud.canal_nombre" />
        <BaseTextarea ref="describe" id="describe" label="Haga un relato cronológico de los hechos que fundamentan su solicitud, de manera clara, numerando cada uno de ellos y de ser posible, especificando circunstancias de tiempo, modo y lugar."
          :disabled="true" placeholder="Mensaje" v-model="solicitude.detalle_solicitud.texto_queja" required="true"
          maxlength="4500" />

        <BaseRadio ref="attorney" name="apoderado"
          label="¿Actúa en nombre propio o es apoderado del consumidor financiero?" :radioOptions="optionsApoderado"
          v-model="solicitude.es_apoderado" required="true" :disabled="true" />
        <div v-if="solicitude.es_apoderado == 2" class="w-full">
          <BaseInput ref="attorneyName" type="text" id="apoderadoName" label="Nombre completo del apoderado:"
            placeholder="Ej: Lina Maria De Castro Jaramillo" v-model="solicitude.apoderado.nombres" required="true" :disabled="true" />
          <BaseInput ref="attorneyEmail" type="text" label="Correo electrónico del apoderado" v-model="solicitude.apoderado.correo"
            placeholder="ejemplo@ejemplo.com" required="true" autocomplete="username" :disabled="true"/>
          
            <div class="flex flex-col gap-2">
              <label
                class="
                  text-xs
                  md:text-xs
                  text-gray-600 text-opacity-80
                  mt-5
                  text-left
                "
                ><b>Anexar autorización del poder</b></label
              >
              <div class="w-11/12 grid grid-cols-1 gap-2">
                <BaseDownloadAttachment
                  v-for="(option, index) in archivos_apoderado"
                  :value="option"
                  :file_name="option.file_name"
                  :key="index"
                />
              </div>
            </div>

        </div>

        <AnnexesJurisdictional :title_accordion="'Anexos de la solicitud'" :attachments="archivos_solicitude" class="mt-6 px-0" />

        <div class="mt-2 flex flex-col gap-4 w-full">
          <h2 class="mt-8 text-lg text-left text-blue">
            <b v-if="solicitude.solicitude_type == 'conciliation'">Información de la conciliación</b>
            <b v-else-if="solicitude.solicitude_type == 'demand'">Información de la demanda</b>
          </h2>
          <BaseTextarea ref="describe" id="describe" label="Indicar sus pretensiones."
          :disabled="true" placeholder="Mensaje" v-model="solicitudes" required="true"
          maxlength="4500" />
          <BaseRadio ref="cuantiayn" name="cuantiayn" label="¿Quiere ingresar cuantia?" :radioOptions="optionsYN"
            :value="solicitude.cuantia ? 1 : 2" :disabled="true" />
          <BaseInput v-if="solicitude.cuantia" type="Number" 
            label="Indique la cuantía de todas sus solicitudes, en caso que ésta supere los 40 SMMLV deberá comparecer por medio de abogado. (Ingrese el valor en pesos, sin puntos ni comas)" 
            placeholder="Ej: 500000" v-model="solicitude.cuantia" :disabled="true" />
          <BaseRadio v-if="solicitude.solicitude_type == 'demand'" ref="indemnizacionyn" name="indemnizacionyn"
            label="¿Pretende indemnización?" :radioOptions="optionsYN" v-model="solicitude.indemnizacion"
            :disabled="true" />
          <div class="w-full mb-2 text-xs md:text-xs flex-col text-left text-gray-500 text-opacity-80"
            v-if="solicitude.indemnizacion == '1' && solicitude.solicitude_type == 'demand'">
            <!-- <p>
              <b>Juramento estimatorio:</b>
            </p>
            <br />
            <a-checkbox :disabled="true" :key="'jura_cb'" :checked="parseInt(solicitude.juramento) == 1">Estimo bajo la
              gravedad del juramento que el valor de mis pretensiones asciende a las
              indicadas</a-checkbox> -->

          <BaseTextarea ref="describe" id="describe" label="Juramento estimatorio. Estimo bajo la gravedad del juramento, que se entiende prestado con la presentación de esta demanda, que el valor de mi pretensión indemnizatoria asciende a: (indique el valor estimado de su pretensión indemnizatoria y discrimine cada uno de los conceptos que la componen)"
          :disabled="true" placeholder="Mensaje" v-model="juramento_estimatorio" required="true"
          maxlength="4500" />
          </div>

          <h2
        class="mt-4 text-lg text-left text-blue"
        v-if="solicitude.solicitude_type == 'demand'|| solicitude.solicitude_type == 'conciliation'"
      >
        <b v-if="solicitude.solicitude_type == 'demand' && solicitude.solicitude_type == 'conciliation'"
          >Otros demandantes / convocantes</b
        >
        <b v-else-if="solicitude.solicitude_type == 'conciliation'">Otros convocantes</b>
        <b v-else>Otros demandantes</b>

        <div
        class="w-full mb-2 text-xs md:text-xs flex-col text-left text-gray-500 text-opacity-80 mt-4"
         
      >
        <div
          class="overflow-x-auto rounded-xl border border-gray-300 bg-white roles"
        >
          <a-table
            class="w-full"
            :columns="columns"
            rowKey="identification_number"
            :data-source="otros_demandantes"
            :pagination="false"
          >
             
          </a-table>
        </div>
      </div>

      </h2>

          <div class="flex flex-col gap-2">
              <label
                class="
                  text-xs
                  md:text-xs
                  text-gray-600 text-opacity-80
                  mt-5
                  text-left
                "
                ><b>Documento cero</b></label
              >
              <div class="w-11/12 grid grid-cols-1 gap-2">
                <BaseDownloadAttachment
                  v-for="(option, index) in archivo_cero"
                  :value="option"
                  :file_name="option.file_name"
                  :key="index"
                />
              </div>
            </div>
          <div v-if="solicitude.solip">
            <h2 class="mt-8 text-lg text-left text-blue">
              <b>Información SOLIP</b>
            </h2>

            <BaseInput type="text" label="Código SOLIP" :value="solicitude.solip.solip_code == '' ? solicitude.solip.solip_code : solicitude.solip?.register_code" placeholder=""
              :disabled="true" />

            <div class="grid grid-cols-2 gap-4">
              <BaseInput type="text" label="Fecha derivado" v-model="solicitude.solip.fecha_derivado" placeholder="dd/mm/yyyyy"
                :disabled="true" />

              <BaseInput type="text" label="Tipo documental" v-model="solicitude.solip.tipo_documental" placeholder=""
                :disabled="true" />
            </div>

            <BaseTextarea label="Terminación" placeholder="" v-model="solicitude.solip.terminacion" required="true"
              maxlength="100" rows="2" :disabled="true" />
          </div>
            <br />
          <div v-if="solicitude.solip && solicitude.solip?.status_solip">
            <h2 class="mt-8 text-lg text-left text-blue">
              <b>Radicación SOLIP</b>              
            </h2>
            <BaseInput type="text" label="Número de radicación en SOLIP" :value="solicitude.solip?.register_code"
              :disabled="true" />
            
            <BaseInput type="text" label="Fecha de rádicación" :value="formatDateToText(solicitude.solip.created_at)"
              :disabled="true" />
            <div v-if='solicitude.solip.status_solip != "Radicada"' class="w-full my-8 grid grid-cols-2 gap-6">
              <div class="h-auto w-full items-center flex flex-row">
                <label class="text-gray-500 text-opacity-80">Estado de radicación</label>
                <button class="flex text-xs items-baseline ml-2 text-blue h-min" @click="reloadRadicadoSolip(solicitude.code)">
                  <a-icon type="reload" :style="{ fontSize: '19px', color: '#3366cc' }" />
                </button>
              </div>
            </div>  
            <BaseSelect label="Estado de radicación" :selectOptions="formOptions.optionsStatesSolip"
              :value="solicitude.solip.status_solip" :disabled="true" />

            <BaseTextarea label="Detalle de radicación" placeholder="" :value="solicitude.solip.detail" 
              maxlength="100" rows="2" :disabled="true" />
            <BaseSelect label="Estado de radicación de anexos" :selectOptions="formOptions.optionsStatesAnnexesSolip"
              :value="solicitude.solip.status_solip_annexes" :disabled="true" />              
          </div>
          <div v-if="solicitude.solip?.annexes && solicitude.solip.annexes?.length >0">          
            <h2 class="mt-8 text-lg text-left text-blue">
              <b>Radicación de anexos en SOLIP</b>
            </h2>
            <template v-for="annexe in solicitude.solip.annexes" >
              <BaseInput type="text" label="Nombre del anexo" v-model="annexe.file_name" :disabled="true" />
              <div v-if='annexe.status_solip_annex != "Radicada"' class="w-full my-8 grid grid-cols-2 gap-6">
                <div class="h-auto w-full items-center flex flex-row">
                  <label class="text-gray-500 text-opacity-80">Estado de radicación de anexo</label>
                  <button class="flex text-xs items-baseline ml-2 text-blue h-min" @click="reloadRadicadoSolipAnnex(solicitude.code, solicitude.solip.register_code, annexe)">
                    <a-icon type="reload" :style="{ fontSize: '19px', color: '#3366cc' }" />
                  </button>
                </div>
              </div>              
              <BaseSelect label="Estado de radicación de anexos" :selectOptions="formOptions.optionsStatesAnnexesSolip"
                :value="annexe.status_solip_annex" :disabled="true" />
              <BaseTextarea label="Detalle de radicación de anexo" :value="annexe.detail_annexes" 
                maxlength="1000" rows="2" :disabled="true" />
                <hr class="w-full my-3" />
            </template>             
          </div>
          <br />
        </div>
        <div v-if="loading" class="fixed inset-0 flex justify-center items-center">
          <a-spin tip="Cargando..." size="large">
          </a-spin>
        </div>
      </form>
    </Wrapper>

  </div>
</template>

<script>
import AnnexesJurisdictional from "@/components/Dashboard/Complaints/ComplaintManagement/AnnexesJurisdictional";
import ComplaintDetailHeaderCard from "@/components/Dashboard/Complaints/ComplaintDetailHeaderCard";
import moment from 'moment';
import 'moment/locale/es';
import { values } from "lodash";
const columns = [
  {
    title: "Nombre completo",
    dataIndex: "full_name",
    key: "full_name",
    width: 120,
  },
  {
    title: "Tipo de identificación",
    dataIndex: "identification_type_nombre",
    key: "identification_type_nombre",
    width: 120,
  },
  {
    title: "Numero de identificación",
    dataIndex: "identification_number",
    key: "identification_number",
    width: 120,
  },
   
];
export default {
  props: ["complaint", "customer"],
  components: {
    ComplaintDetailHeaderCard,
    AnnexesJurisdictional
  },
  data() {
    return {
      solicitude: {
        user_cf: {},
        anexos: [],
        places: {},
        entidad: {},
        detalle_solicitud: {},
        apoderado: {}
      },
      plainOptions: [
        {
          title: 'Conciliación:',
          subtitle: `Será convocado para que asista a una audiencia junto con la entidad para buscar una solución a su inconformidad, con la participación de un Conciliador de la Superfinanciera.`,
          value: '2'
        },
        {
          title: 'Demanda:',
          subtitle: `Se adelanta un proceso con un funcionario de la Superfinanciera quien actúa como Juez entre usted y la entidad para decidir sobre su inconformidad.`,
          value: '1'
        },
        {
          title: 'Queja:',
          subtitle: `Su solicitud será atendida directamente por la entidad financiera.`,
          value: '0'
        },
      ],
      optionsForm: [false, false, false],
      modalVisible: false,
      evidences: {
        conciliation: {
          url: null,
          folder: null
        },
        demand: {
          url: null,
          folder: null
        }
      },
      cuantiaYN: 2,
      ops: [],
      feedback: "",
      abroad: "",
      specialCondition: "",
      complaintFiles: [],
      general_references: "!,2,6,7,8,9",
      authorizationFiles: [],
      filesSize: 0,
      optionsMunicipalities: [],
      optionsMunicipalities2: [],
      optionsReasons: [],
      optionsProducts: [],
      optionsYN: [
        { id: 1, name: "Sí" },
        { id: 2, name: "No" },
      ],
      optionsApoderado: [
        { id: 1, name: "Actúo en nombre propio" },
        { id: 2, name: "Soy apoderado" },
      ],
      disableButton: false,
      loading: false,
      loadingModal: false,
      currentStepFile: 0,
      stepStyle: {
        boxShadow: '0px -1px 0 0 #e8e8e8 inset',
      },
      archivos_apoderado:[],
      archivo_cero:[],
      archivos_solicitude:[],
      columns,
      otros_demandantes: [],
      solicitudes:"",
      juramento_estimatorio:""
    };
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
    user: function () {
      let user = this.$store.state.session.user;
      return user;
    },
  },
  async created() {
    await this.getSolicitudeInfo();
  },
  methods: {
    async getSolicitudeInfo() {
      const id = this.$route.params.id;

      this.loading = true;
      let { data, error } = await this.$api.getDetailSolicitude(`${id}`);
      console.log('data solicitude', data)
      if (error) {
        this.showError(error);
        return
      }
      // this.juramento_estimatorio =
      this.solicitude = data[id];
      this.solicitudes = this.solicitude.solicitudes;
      this.juramento_estimatorio = this.solicitude.juramento_estimatorio;
      this.otros_demandantes = Array.isArray(this.solicitude.otros_demandantes) ? [...this.solicitude.otros_demandantes] : []; 
      let condicion_especial = this.solicitude.user_cf.condicion_especial;
      this.solicitude.user_cf.municipio_nombre_user = this.solicitude.user_cf.municipio_nombre_user == "null"? "": this.solicitude.user_cf.municipio_nombre_user;
      this.solicitude.user_cf.direccion_user = this.solicitude.user_cf.direccion_user == "null"? "": this.solicitude.user_cf.direccion_user;
      this.specialCondition = condicion_especial == "" ? "": condicion_especial=="98"?2:1;
      this.archivos_apoderado = this.solicitude.anexos.filter(anexo => anexo.type === 'apoderado');
      this.archivo_cero = this.solicitude.anexos.filter(anexo => anexo.type === 'cero');
      this.archivos_solicitude = this.solicitude.anexos.filter(anexo => anexo.type === 'solicitude');
      this.solicitude.code = id
      console.log('this.solicitude', this.archivos_solicitude)
      this.loading = false;
    },
    loadedFile() {
      this.loadingModal = false;
    },
    showError(error) {
      this.loading = false;
      this.disableButton = false;
      if (error.status_code === 400 || error.status_code === 404) {
        let err;
        for (let i in error.message) {
          err = error.message[i][0];
        }
        this.feedback = err;
      } else {
        this.feedback = "Hubo un error al obtener el registro";
      }
      this.$notification.error({
        message: "Notificación",
        description: this.feedback,
      });
    },
    formatDateToText(date) {
      return moment(date).format('D [de] MMMM [de] YYYY, HH:mm:ss');
    },
    async reloadRadicadoSolip(document_id) {      
      this.loading = true;
      try {
        let query = '?doc_id=' + document_id;
        const urlRequest = `/jurisdiccional-servi/retry-solip-upload${query}`;        
        const {error, data: response} = await this.$api.getResource(urlRequest);
        if (error) {
          throw error;
        }
        if(response){
          this.$router.go(0);
        } else {
          this.$notification.error({
            message: 'Error',
            description: 'Ocurrió un error al intentar reenviar el radicado',
          });
        }                      
      } catch (error) {
        console.log('Error en reloadRadicadoSolip', error);
        this.$notification.error({
          message: 'Error',
          description: 'Ocurrió un error al intentar reenviar el radicado',
        });       
      }        
      this.loading = false;
    },
    async reloadRadicadoSolipAnnex(document_id, register_code, annexes) {      
      this.loading = true;
      try {
        let payload = {
            "document_id": document_id,
            "register_code": register_code,
            "bucket": annexes.bucket,
            "fullpath": annexes.full_path,
            "filename": annexes.file_name,
        };
        const {error, data: response} = await this.$api.postReloadRadicadoSolipAnnex(payload);
        if (error) {
          throw error;
        }
        if (response) {
          this.$router.go(0);
        } else {
          this.$notification.error({
            message: 'Error',
            description: 'Ocurrió un error al intentar reenviar el radicado de anexo',
          });
        }
      } catch (error) {
        console.log('Error en reloadRadicadoSolipAnnex', error);
        this.$notification.error({
          message: 'Error',
          description: 'Ocurrió un error al intentar reenviar el radicado de anexo',
        });       
      }        
      this.loading = false;
    }
  },
  watch: {}
};
</script>